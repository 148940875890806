function reformatStockBatches(batches) {
  let uniqueStocksList = [];
  batches.forEach((stockBatch) => {
    const { accion } = stockBatch;
    const { ticker } = accion;
    const stockIndex = uniqueStocksList.findIndex((item) => item.accion.ticker === ticker);
    if (stockIndex === -1) {
      uniqueStocksList.push({
        accion: accion,
        lotes: [stockBatch],
      });
    } else {
      uniqueStocksList[stockIndex].lotes.push(stockBatch);
    }
  });
  return uniqueStocksList;
};

function calculateStockHoldingsValues(stockObject, quote) {
  // Function that receives a stock object and the current quote and returns:
  // {
  //    costs: Float, the total amount of money used to buy the stock
  //    ingress: Float, the total amount of money received from all the stock that have been sold
  //    currentCost: Float, the amount of money used to buy the stock minus the cost of the stock sold
  //    currentValue: Float, the value of current stock holdings according to the current quote
  //    numberOfStocks: Float, the net amount of stocks
  //    realizedGains: Float, the difference between the amount of money received from selling the stock and the cost of the stock sold
  //    realizedGainsPercentage: Float, the percentage of realized gains
  //    unrealizedGains: Float, the difference between the value of the current stock holdings and the current cost
  //    unrealizedGainsPercentage: Float, the percentage of unrealized gains
  // }

  // Obtener la cotización actual
  const currentQuote = quote.cierre;

  // Ordenar lotes por fecha
  const sortedLotes = stockObject.lotes.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));

  // Inicialización de variables
  let costs = 0; // Dinero total gastado en la compra de acciones
  let ingress = 0; // Dinero total recibido por la venta de acciones
  let currentCost = 0; // Costo total ajustado después de ventas
  let numberOfStocks = 0; // Cantidad neta de acciones
  let realizedGains = 0; // Ganancia realizada en ventas
  let currentValue = 0; // Valor actual del total de acciones

  // Procesar lotes ordenados por fecha
  sortedLotes.forEach((lote) => {
    const { cantidad, precio } = lote;

    if (precio < 0) {
      // Es una compra, el precio es negativo y corrsponde al total gastado en el total de acciones del lote
      costs += precio; // Suma el costo total gastado en compras
      currentCost += precio; // Incrementa el costo ajustado
      numberOfStocks += cantidad; // Incrementa la cantidad de acciones
    } else {
      // Es una venta
      const averageCostPerStock = numberOfStocks > 0 ? currentCost / numberOfStocks : 0;
      const costOfStocksSold = cantidad * averageCostPerStock; // Es negativo porque es un costo
      ingress += precio; // Suma el dinero recibido por la venta
      currentCost -= costOfStocksSold; // Resta el costo de las acciones vendidas
      numberOfStocks -= cantidad; // Reduce la cantidad de acciones
      realizedGains += precio + costOfStocksSold; // Calcula la ganancia realizada
    }
  });

  // Calcular el valor actual y las ganancias no realizadas
  currentValue = numberOfStocks * currentQuote;
  const unrealizedGains = currentValue + currentCost;

  // Calcular porcentajes
  const realizedGainsPercentage = costs < 0 ? (-realizedGains / costs) * 100 : 0; // se calcula sobre el total de los costos, no los actuales
  const unrealizedGainsPercentage = currentCost < 0 ? (-unrealizedGains / currentCost) * 100 : 0;

  return {
    costs,
    ingress,
    currentCost,
    currentValue,
    numberOfStocks,
    realizedGains,
    realizedGainsPercentage,
    unrealizedGains,
    unrealizedGainsPercentage,
  };
}

function calculatePortfolioHoldingsValues(portafolio, stockQuotesObject) {
  // Function that receives the portfolio and using the function calculateStockHoldingsValues returns:
  // {
  //    costs: Float, the total amount of money used to buy all the stocks in the portfolio
  //    ingress: Float, the total amount of money received from all the stock that have been sold in the portfolio
  //    currentCost: Float, the amount of money used to buy all the stocks in the portfolio minus the cost of the stock sold
  //    currentValue: Float, the value of current stock holdings according to the current quote
  //    numberOfStocks: Float, the net amount of stocks in the portfolio
  //    realizedGains: Float, the difference between the amount of money received from selling the stock and the cost of the stock sold
  //    realizedGainsPercentage: Float, the percentage of realized gains
  //    unrealizedGains: Float, the difference between the value of the current stock holdings and the current cost
  //    unrealizedGainsPercentage: Float, the percentage of unrealized gains
  // }
  // Inicialización de variables
  let costs = 0;
  let ingress = 0;
  let currentCost = 0;
  let currentValue = 0;
  let numberOfStocks = 0;
  let realizedGains = 0;

  // Procesar cada acción en el portafolio
  portafolio.forEach((stock) => {
    const currentQuote = stockQuotesObject[stock.accion.ticker];
    const stockValues = calculateStockHoldingsValues(stock, currentQuote);

    costs += stockValues.costs;
    ingress += stockValues.ingress;
    currentCost += stockValues.currentCost;
    currentValue += stockValues.currentValue;
    numberOfStocks += stockValues.numberOfStocks;
    realizedGains += stockValues.realizedGains;
  });

  // Calcular ganancias no realizadas
  const unrealizedGains = currentValue + currentCost;

  // Calcular porcentajes
  const realizedGainsPercentage = costs < 0 ? (-realizedGains / costs) * 100 : 0;
  const unrealizedGainsPercentage = currentCost < 0 ? (-unrealizedGains / currentCost) * 100 : 0;

  return {
    costs,
    ingress,
    currentCost,
    currentValue,
    numberOfStocks,
    realizedGains,
    realizedGainsPercentage,
    unrealizedGains,
    unrealizedGainsPercentage,
  };

}




export { reformatStockBatches, calculateStockHoldingsValues, calculatePortfolioHoldingsValues };