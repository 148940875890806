// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { FormControl, InputAdornment, Typography, Stack, Switch, TextField, OutlinedInput, InputLabel } from '@mui/material';

function MontoInput(props) {

  // Deconstructing some props to set default values
  const { switchingText = { ingresoText: "Convertir a ingreso", egresoText: "Convertir a egreso" } } = props;

  // State constants
  const [ammount, setAmmount] = useState(Math.abs(parseFloat(props.ammount)));
  const [ready, setReady] = useState(false);
  const [localIsExpense, setLocalIsExpense] = useState(props.isExpense !== undefined ? props.isExpense : true);

  useEffect(() => {
    if (ready) {
      handleBlur();
    }
  }, [localIsExpense, ready])

  // Handlers
  const handleAmmountChange = event => {
    if (event.target.value === '') {
      setAmmount(event.target.value);
    } else {
      if (!isNaN(parseFloat(event.target.value))) {
        if (parseFloat(event.target.value) >= 0) {
          setAmmount(Math.abs(event.target.value));
        }
      }
    }
  }

  const handleBlur = () => {
    if (ammount === '') {
      props.onChange(0);
    } else {
      props.onChange(localIsExpense ? -Math.abs(ammount) : Math.abs(ammount));
    }
  }

  return <Stack>
    {props.allowSwitching ?
      <Stack direction="row">
        <Stack justifyContent="center">
          <Typography sx={{ paddingLeft: "3px" }}>{props.isExpense ? switchingText.ingresoText : switchingText.egresoText}</Typography>
        </Stack>
        <Switch
          checked={(props.isExpense === undefined || props.isExpense) ? !localIsExpense : localIsExpense}
          onChange={() => {
            setLocalIsExpense(prev => !prev);
            setReady(true);
          }}
        />
      </Stack>
      : <></>
    }
    <FormControl fullWidth>
      <InputLabel htmlFor="monto">{props.label ? props.label : "Monto"}</InputLabel>
      <OutlinedInput
        id="monto"
        readOnly={props.readOnly ? props.readOnly : false}
        value={ammount}
        onChange={handleAmmountChange}
        onFocus={event => event.target.select()}
        onBlur={handleBlur}
        variant={props.readOnly === true ? "filled" : "outlined"}
        type="number"
        sx={{ color: localIsExpense ? "red" : "green" }}
        startAdornment={props.noAdornment ?
          <></> :
          <InputAdornment position="start">{localIsExpense ? '- $' : '$'}</InputAdornment>}
        label={props.label ? props.label : "Monto"}
      />

    </FormControl>
  </Stack >


}

MontoInput.propTypes = {
  ammount: PropTypes.number.isRequired, // Controlled value
  onChange: PropTypes.func.isRequired, // Function to be called when a value changes
  label: PropTypes.string, // Label of the input field, default: "Monto"
  noAdornment: PropTypes.bool, // If true, dont show currency sign adornment
  readOnly: PropTypes.bool, // If true, no modifications are allowed
  isExpense: PropTypes.bool.isRequired, // Indicates whether the user in registering an expense or an income
  allowSwitching: PropTypes.bool, // Indicates if the user is allowed to switch between expense and income
  switchingText: PropTypes.shape({ ingresoText: PropTypes.string, egresoText: PropTypes.string }), // Text to show when switching between expense and income
}

export default MontoInput;