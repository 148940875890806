// My components imports
import { applyAmountFilters } from "../queries";

export function getStockBatchesQuery(searchObject) {
  // Deconstruct searchObject
  const {
    initialDate,
    finalDate,
    accounts,
    movementTypes,
  } = searchObject;

  let query = {
    initialDate: initialDate,
    finalDate: finalDate,
    accounts: accounts,
  };

  query = applyAmountFilters(query, movementTypes);

  return query;
}