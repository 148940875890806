// React imports
import { useState } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack, Dialog, DialogContent, TextField } from '@mui/material';

// My component imports
import { scheduledMovementPropType } from '../../utils/myPropTypes';
import { deleteMovimientoProgramado, deleteMovimientoTarjetaProgramado } from '../../utils/api';
import CreateModifyScheduledMovement from '../app/createModifyScheduledMovement';
import CreateModifyScheduledCardMovement from '../app/createModifyScheduledCardMovement';
import { getScheduledListItem } from '../../utils/visualizations';
import { getObjectType } from '../../utils/misc';

// Auxiliary functions
function createScheduledRegistersFilter(searchText) {
  // Normaliza un texto eliminando tildes y haciendo minúsculas
  const normalizar = texto => texto
    .normalize('NFD') // Descompone caracteres diacríticos
    .replace(/[\u0300-\u036f]/g, '') // Elimina diacríticos
    .toLowerCase(); // Convierte a minúsculas

  // Normaliza el texto del filtro
  const filtroNormalizado = normalizar(searchText);

  // Retorna una función de filtro para usar con Array.prototype.filter
  return register => {
    const nombreNormalizado = normalizar(register.agenda.nombre);
    const comentarioNormalizado = normalizar(register.comentario);
    return nombreNormalizado.includes(filtroNormalizado) || comentarioNormalizado.includes(filtroNormalizado);
  };
}

function ScheduledRegistersList({ scheduledRegisters, updateList, compact }) {

  // State constants
  const [showModificationDialog, setShowModificationDialog] = useState(false);
  const [currentRegister, setCurrentRegister] = useState(null);
  const [searchText, setSearchText] = useState('');

  // Handlers
  const handleDeleteRegister = register => {
    const deleteFunction = getObjectType(register).type === 'MovimientoProgramado' ? deleteMovimientoProgramado : deleteMovimientoTarjetaProgramado;
    deleteFunction(register.id)
      .then(result => {
        updateList();
      })
      .catch(err => console.error('Ups! Error deleting register:', err));
  }

  const handleModifyRegister = register => {
    console.log('Modifying register:', register);
    setCurrentRegister(register);
    setShowModificationDialog(true);
  }

  const handleModificationCancel = () => {
    setShowModificationDialog(false);
    setCurrentRegister(null);
  }

  const handleModifyRegisterConfirmation = register => {
    updateList();
  }

  const handleSearchTextChange = event => {
    setSearchText(event.target.value);
  }


  return <Stack spacing={1}>
    <Dialog open={showModificationDialog} onClose={() => setShowModificationDialog(false)}>
      <DialogContent>
        {currentRegister && (getObjectType(currentRegister).type === 'MovimientoProgramado' ?
          <CreateModifyScheduledMovement
            initialValues={{
              initialScheduledMovement: currentRegister,
              intent: 'modify'
            }}
            onSubmit={handleModifyRegisterConfirmation}
            onCancel={handleModificationCancel}
          /> :
          <CreateModifyScheduledCardMovement
            initialValues={{
              initialScheduledMovement: currentRegister,
              intent: 'modify'
            }}
            onSubmit={handleModifyRegisterConfirmation}
            onCancel={handleModificationCancel}
          />
        )}
      </DialogContent>
    </Dialog>
    <TextField label="Buscar" value={searchText} onChange={handleSearchTextChange} />
    {scheduledRegisters.filter(createScheduledRegistersFilter(searchText)).map((scheduledMovement, index) => {
      return getScheduledListItem(scheduledMovement, {
        key: index,
        compact,
        onDelete: () => handleDeleteRegister(scheduledMovement),
        onModify: () => handleModifyRegister(scheduledMovement)
      });
    })
    }
  </Stack>
}

ScheduledRegistersList.propTypes = {
  scheduledRegisters: PropTypes.arrayOf(PropTypes.oneOf([scheduledMovementPropType])).isRequired,
  updateList: PropTypes.func.isRequired,
  compact: PropTypes.bool,
};

export default ScheduledRegistersList;