// React imports
import { useState, useEffect, useRef } from "react";

// Proptypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack, TextField, Button, Chip, useTheme } from "@mui/material";

// My components imports
import AccionList from "../../lists/investment/accionList";
import { accionPropType } from "../../../utils/myPropTypes";
import MyLoadingList from "../../basic/myLoadingList";
import { TickerSearch } from "../../../utils/investmentApi";


function AccionPicker({ initialAccionList, initialSearchText = "", onSelect, initialSelectedStock = null, disabled = false }) {

  // Constants
  let getTickers = useRef(null);
  let searchTimeout = useRef(null);
  const MAX_RESULTS = 50;
  const SEARCH_DELAY = 800;
  const theme = useTheme();

  // State constants
  const [tickerSearchText, setTickerSearchText] = useState(initialSearchText);
  const [accionList, setAccionList] = useState(initialAccionList);
  const [loading, setLoading] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [thereIsNext, setThereIsNext] = useState(false);
  const [ready, setReady] = useState(false);
  const [selectedStock, setSelectedStock] = useState(initialSelectedStock);

  // Effect hooks
  useEffect(() => {
    setAccionList(initialAccionList);
  }, [initialAccionList]);

  useEffect(() => {
    getTickers.current = new TickerSearch(tickerSearchText, MAX_RESULTS);
  }, [tickerSearchText]);

  useEffect(() => {
    setTickerSearchText(initialSearchText);
  }, [initialSearchText]);

  useEffect(() => {
    setThereIsNext(getTickers.current.hasNext());
  }, [accionList]);

  useEffect(() => {
    console.log("Setting selected stock inside accion picker", initialSelectedStock);
    setSelectedStock(initialSelectedStock);
  }, [initialSelectedStock]);

  useEffect(() => {
    console.log("Selected stock changed", selectedStock);
  }, [selectedStock]);

  useEffect(() => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    if (tickerSearchText) {
      searchTimeout.current = setTimeout(() => {
        handleSearch();
      }, SEARCH_DELAY);
    } else {
      if (ready) {
        initialState();
      }
    }
  }, [tickerSearchText]);

  // Handlers
  const handleTyping = searchText => {
    setTickerSearchText(searchText);
  }

  const handleSearch = () => {
    setLoading(true);
    setReady(true);
    getTickers.current.next()
      .then(response => {
        setAccionList(response.results);
        setLoading(false);
        // foccus cursor on the search box
        document.getElementById("ticker").focus();
      })
      .catch(error => {
        console.error("Error searching tickers", error);
      });
  }

  const handleNext = () => {
    setLoadingNext(true);
    getTickers.current.next()
      .then(response => {
        setAccionList(prev => [...prev, ...response.results]);
        setLoadingNext(false);
      })
      .catch(error => {
        console.error("Error searching tickers", error);
      });
  }

  const handleSelect = (stock) => {
    setSelectedStock(stock);
    onSelect(stock);
  }

  const handleClearSelection = () => {
    setSelectedStock(null);
    initialState();
    onSelect(null);
  }

  const initialState = () => {
    setAccionList([]);
    setThereIsNext(false);
    setReady(false);
    setSelectedStock(null);
  }

  return <Stack spacing={1}>
    {
      selectedStock !== null ?
        <Chip
          label={selectedStock.nombre + " (" + selectedStock.ticker + ")"}
          onDelete={disabled ? null : handleClearSelection}
          onClick={disabled ? null : handleClearSelection}
          color="primary"
        /> :
        <TextField
          id="ticker"
          label="Ticker"
          variant="outlined"
          size="small"
          fullWidth
          value={tickerSearchText}
          onChange={(e) => handleTyping(e.target.value)}
        />
    }
    {loading ? <MyLoadingList /> : <AccionList acciones={accionList} onSelect={handleSelect} />}
    <Stack direction="row" spacing={1}>
      {
        loadingNext ?
          <MyLoadingList /> :
          thereIsNext && ready ?
            <Button variant="contained" color="primary" onClick={handleNext} disabled={!thereIsNext} fullWidth>Cargar mas resultados</Button> :
            null
      }
    </Stack>
  </Stack>

}

// PropTypes
AccionPicker.propTypes = {
  initialAccionList: PropTypes.arrayOf(accionPropType),
  initialSearchText: PropTypes.string,
  onSelect: PropTypes.func,
  initialSelectedStock: accionPropType,
  disabled: PropTypes.bool,
}

export default AccionPicker;