// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI components imports
import { Stack } from "@mui/system";

// My component imports
import MyPaper from "../basic/myPaper.js";
import { deleteCardMovement, getCards, getCategories } from "../../utils/api.js";
import AddButton from "../basic/addButton.js";
import RegisterCardMovement from "./registerCardMovement.js";
import MovimientoTarjetaListItem from "../basic/movimientoTarjetaListItem.js";
import { emptyCardMovement, SUCCESS_FEEDBACK_TIMER } from "../../utils/constants.js";
import MyLoading from "../basic/MyLoading";
import { CardError } from "../basic/errors.js";
import MyLoadingList from "../basic/myLoadingList.js";

// Auxiliary functions
function updateInitialValues(newMov, intent, cards, categories) {
  return {
    cards: cards,
    categories: categories,
    initialCardMovement: intent === 'create' ? emptyCardMovement : newMov,
    intent: intent,
  }
}

function RegisterCardMovementPage(props) {

  // Constants
  const emptyInitialValues = {
    cards: [],
    categories: [],
    intent: 'create',
    initialCardMovement: emptyCardMovement,
  }

  // State constants
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const [cards, setCards] = useState(null);
  const [categories, setCategories] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    Promise.all([getCards(), getCategories()])
      .then(response => {
        if (isMounted) {
          setCards(response[0].results);
          setCategories(response[1].results);
        }
      })
      .catch(err => console.log(err));
    return () => { isMounted = false; }
  }, []);

  useEffect(() => {
    let isMounted = true;
    // Stop loading when cards and categories are fetched
    if (isMounted && cards && categories) {
      if (cards.lenght === 0 || categories.length === 0) {
        // If there are no cards or categories, show error
        setError(true);
      } else {
        // If there are cards and categories, stop loading
        setInitialLoading(false);
      }
    }
    return () => { isMounted = false; }
  }, [cards, categories]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && initialValues.intent === 'create') {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
    return () => { isMounted = false; }
  }, [initialValues])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !loadingDelete) {
      setShowDeleteSuccess(false);
    }
  }, [loadingDelete])

  // Handlers
  const handleCardMovementChange = mov => {
    const newCardMov = updateInitialValues(mov, 'modify', cards, categories);
    setInitialValues(newCardMov);
  }

  const handleNewCardMovementClick = event => {
    const newCardMov = updateInitialValues(emptyCardMovement, 'create', cards, categories);
    setInitialValues(newCardMov);
  }

  const handleCardMovementModifyClick = mov => {
    setShowForm(true);
  }

  const handleDelete = mov => {
    setLoadingDelete(true);
    deleteCardMovement(initialValues.initialCardMovement.id)
      .then(response => {
        setShowDeleteSuccess(true);
        setTimeout(() => {
          setLoadingDelete(false);
          setInitialValues(emptyInitialValues);
        }, [SUCCESS_FEEDBACK_TIMER])
      })
      .catch(err => console.log(err));
  }

  return error ? <MyPaper><CardError /></MyPaper> :
    initialLoading ? <MyPaper><MyLoadingList /></MyPaper> :
      showForm ?
        <MyPaper>
          <RegisterCardMovement
            initialValues={initialValues}
            cards={cards}
            categories={categories}
            onSubmit={handleCardMovementChange}
            isExpense={initialValues.intent === "modify" ? initialValues.initialCardMovement.monto < 0 : props.isExpense}
            onCancel={() => setShowForm(false)}
            allowSwitching={props.allowSwitching}
          />
        </MyPaper> :
        <Stack>
          <MyPaper>
            {initialValues.initialCardMovement.tarjeta ? (
              loadingDelete ?
                <Stack alignItems="center"><MyLoading success={showDeleteSuccess} /></Stack> :
                <MovimientoTarjetaListItem
                  onDelete={handleDelete}
                  onModify={handleCardMovementModifyClick}
                  movimientoTarjeta={initialValues.initialCardMovement}
                />
            ) : <></>}
          </MyPaper>
          <AddButton onClick={handleNewCardMovementClick} />
        </Stack>
}

RegisterCardMovementPage.propTypes = {
  isExpense: PropTypes.bool, // Value that indicates whether user is registering an expense or an income
  allowSwitching: PropTypes.bool, // Indicates if the user is allowed to switch between expense and income
}

export default RegisterCardMovementPage;