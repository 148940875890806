// PropTypes imports
import PropTypes from 'prop-types';

// React imports
import { useEffect, useState } from 'react';

// MUI imports
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { MenuItem, Select, Stack, Typography } from "@mui/material";

// My component imports
import LabelInput from '../inputs/labelInput.js';
import EasyDatePicker from '../inputs/easyDatePicker.js';
import { getButtonText } from "../../utils/misc.js";
import MontoInput from "../inputs/montoInput.js";
import TarjetaPicker from "../inputs/tarjetaPicker.js";
import CategoriaPicker from "../inputs/categoriaPicker.js";
import MonedaPicker from "../inputs/monedaPicker.js";
import themes from "../../styles/themes.js";

function MovementCardForm(props) {

  // State constants
  const [selectedCard, setSelectedCard] = useState(props.initialCardMovement.tarjeta ? props.initialCardMovement.tarjeta : props.cards[0]);
  const [selectedCategory, setSelectedCategory] = useState(
    props.initialCardMovement.categoria ?
      props.initialCardMovement.categoria :
      props.categories.filter(cat => cat.nombre === 'Compras')[0] // For credit card movements, 'Compras' is the most frequently used category
  );
  const [ammount, setAmmount] = useState(props.initialCardMovement.estado_de_cuenta ?
    parseFloat(props.initialCardMovement.monto * props.initialCardMovement.cantidad_de_cuotas).toFixed(2) : 0
  );
  const [labels, setLabels] = useState(props.initialCardMovement.etiquetas);
  const [date, setDate] = useState(props.initialCardMovement.fecha);
  const [comment, setComment] = useState(props.initialCardMovement.comentario);
  const [installments, setInstallments] = useState(props.initialCardMovement.cantidad_de_cuotas);
  const [selectedCurrency, setSelectedCurrency] = useState(
    props.initialCardMovement.moneda ?
      props.initialCardMovement.moneda :
      (selectedCard ? selectedCard.saldos_de_tarjeta.filter(saldo => saldo.moneda.nombre_corto === 'UYU')[0].moneda : null)
  ); // Set default currency to UYU
  const [formIsReady, setFormIsReady] = useState(false);
  const [hintText, setHintText] = useState('');

  // Effect hooks
  useEffect(() => {
    if (selectedCard && selectedCurrency) {
      if (selectedCard.saldos_de_tarjeta.filter(balance => balance.moneda.id === selectedCurrency.id).length === 0) {
        setSelectedCurrency(null);
      }
    }
  }, [selectedCard, selectedCurrency])

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (selectedCard && selectedCategory && date && selectedCurrency) {
        setFormIsReady(true);
      } else {
        setFormIsReady(false);
      }
    }
    return () => { isMounted = false }
  }, [selectedCard, selectedCategory, date, selectedCurrency]);

  useEffect(() => {
    let isMounted = true;
    let icompleteFields = [];
    if (!selectedCard) {
      icompleteFields.push('la tarjeta');
    }
    if (!selectedCategory) {
      icompleteFields.push('la categoría');
    }
    if (!date) {
      icompleteFields.push('la fecha');
    }
    if (!selectedCurrency) {
      icompleteFields.push('la moneda');
    }
    if (isMounted) {
      // Give user feedback on mandatory fields, add a 'y' before the last one, only if there are more than one. 
      // At last add a recommendation to add labes aswell, only if there are no labels
      if (icompleteFields.length > 0) {
        let hint = 'Antes de registrar el movimiento por favor completa ';
        if (icompleteFields.length > 1) {
          hint += icompleteFields.slice(0, -1).join(', ') + ' y ' + icompleteFields.slice(-1);
        } else {
          hint += icompleteFields[0];
        }
        if (labels.length === 0) {
          hint += '. También te recomendamos agregar etiquetas para poder filtrar tus movimientos más fácilmente.';
        } else {
          hint += '.';
        }
        setHintText(hint);
      } else {
        setHintText('');
      }
    }
    return () => { isMounted = false }
  }, [selectedCard, selectedCategory, selectedCurrency, date, labels]);

  // Change handlers
  const handleCommentChange = event => setComment(event.target.value);
  const handleInstallmentsChange = event => setInstallments(event.target.value);
  const modifyCardMovementHandler = event => {
    props.onSubmit({
      id: props.initialCardMovement ? props.initialCardMovement.id : 0,
      tarjeta: selectedCard,
      moneda: selectedCurrency,
      monto: ammount,
      fecha: date,
      categoria: selectedCategory,
      comentario: comment,
      cantidad_de_cuotas: installments,
      etiquetas: labels,
    });
  };

  return <Stack spacing={1}>
    <FormControl fullWidth>
      <EasyDatePicker
        helperValues={['hoy', 'ayer', 'antesDeAyer']}
        onChange={newDate => setDate(newDate)}
        initialValue={date}
      />
    </FormControl>

    <FormControl fullWidth>
      <TarjetaPicker
        multiple={false}
        cards={props.cards.filter(card => card.moneda.nombre_corto === 'UYU')}
        initialValues={selectedCard}
        onChange={newValue => setSelectedCard(newValue)}
      />
    </FormControl>

    <FormControl fullWidth>
      <MonedaPicker
        currencies={selectedCard ? selectedCard.saldos_de_tarjeta.map(saldo => saldo.moneda) : []}
        multiple={false}
        initialValues={selectedCurrency}
        onChange={newValue => setSelectedCurrency(newValue)}
      />
    </FormControl>

    <FormControl fullWidth>
      <MontoInput
        ammount={ammount}
        onChange={value => setAmmount(value)}
        label="Monto"
        isExpense={props.isExpense}
        allowSwitching={props.allowSwitching}
      />
    </FormControl>

    <FormControl fullWidth>
      <InputLabel id="cuotasLabel">Cuotas</InputLabel>
      <Select
        labelId="cuotasLabel"
        id="cuotas"
        value={installments}
        label="Cuotas"
        onChange={handleInstallmentsChange}
      >
        {[...Array(30).keys()].map(item => <MenuItem value={item + 1} key={item}>{item + 1}</MenuItem>)}
      </Select>
    </FormControl>

    <FormControl fullWidth>
      <CategoriaPicker
        categories={props.categories}
        initialValues={selectedCategory}
        onChange={newValue => setSelectedCategory(newValue)}
        multiple={false}
      />
    </FormControl>

    <FormControl fullWidth>
      <LabelInput initialLabels={labels} onChange={(selectedLabels) => setLabels(selectedLabels)} />
    </FormControl>

    <FormControl fullWidth>
      <TextField
        id="comentario"
        value={comment}
        onChange={handleCommentChange}
        variant="outlined"
        type="text"
        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
        label="Comentario"
      />
    </FormControl>

    {
      formIsReady ?
        <Button variant="contained" onClick={modifyCardMovementHandler} fullWidth>
          {getButtonText(props.intent, props.isExpense)}
        </Button> :
        <Stack>
          <Typography variant="caption" sx={{ color: themes.primary.palette.primary.cancel, padding: '5px' }}>{hintText}</Typography>
        </Stack>
    }

    <Button variant="contained" color="cancel" onClick={props.onCancel} fullWidth>
      Cancelar
    </Button>

  </Stack>
}

MovementCardForm.propTypes = {
  initialCardMovement: PropTypes.shape({
    tarjeta: PropTypes.object, // Value to initializa card selection
    categoria: PropTypes.object, // Value to initialize category selection
    moneda: PropTypes.object, // Value for currency initialization
    etiquetas: PropTypes.arrayOf(PropTypes.object), // Value to initialize labels
    fecha: PropTypes.string, // Value to initialize date
    comentario: PropTypes.string, // Value to initialize comment
    monto: PropTypes.number, // Value to initialize ammount
    cantidad_de_cuotas: PropTypes.number // Value to initializa the number of installments
  }).isRequired,// values to initialize data inputs
  cards: PropTypes.arrayOf(PropTypes.object).isRequired, // List containing all available cards
  categories: PropTypes.arrayOf(PropTypes.object).isRequired, // List containing all available categories
  intent: PropTypes.oneOf(['create', 'modify']).isRequired, // Value that determines if user is creating or modifying a MovimientoTarjeta
  isExpense: PropTypes.bool.isRequired, // Indicates whether the user in registering an expense or an income
  onSubmit: PropTypes.func.isRequired, // Function to be called when user click on submit button
  onCancel: PropTypes.func.isRequired, // Function to be called when user click on cancel button
  allowSwitching: PropTypes.bool, // Indicates if the user is allowed to switch between expense and income
}

export default MovementCardForm;

