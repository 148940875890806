// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Autocomplete, Chip, TextField } from "@mui/material";
import { currencyPropType } from "../../utils/myPropTypes";

// Auxiliary functions
function pushCurrency(currency, list) {
  let alreadyIn = false;
  list.forEach(item => {
    if (item.id === currency.id) {
      alreadyIn = true;
    }
  })
  if (!alreadyIn) {
    list.push(currency);
  }
  return list;
}

function MonedaPicker(props) {

  // Decostructing props
  const { onChange, currencies, initialValues, multiple, readOnly } = props;

  // State constants
  const [selectedCurrencies, setSelectedCurrencies] = useState(initialValues);
  const [selectedCurrency, setSelectedCurrency] = useState(initialValues);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (ready && isMounted) {
      if (multiple) {
        onChange(selectedCurrencies);
      } else {
        onChange(selectedCurrency);
      }
    }
    return () => { isMounted = false };
  }, [selectedCurrencies, selectedCurrency, ready, multiple, onChange])

  return <Autocomplete
    fullWidth
    multiple={multiple}
    disabled={readOnly}
    filterSelectedOptions={true}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    clearOnBlur
    id="currencyId"
    options={currencies}
    defaultValue={multiple ? [] : null}
    getOptionLabel={currency => {
      if (currency !== null && currency.nombre_corto !== undefined) {
        return currency.nombre + ' (' + currency.nombre_corto + ')';
      } else {
        return 'N/A';
      }
    }}
    value={multiple ? selectedCurrencies : selectedCurrency}
    onChange={(event, newValue) => {
      if (multiple) {
        // First make a copy of the object
        let myNewList = JSON.parse(JSON.stringify(newValue));

        const lastItem = myNewList.pop();

        // if lastItem is undefined set selectedAccounts to empty list
        if (typeof lastItem === 'undefined') {
          setSelectedCurrencies([]);
          setReady(true);
          return null;
        };

        // User is trying to add an existing label, make sure the label isn't already selected
        setSelectedCurrencies(pushCurrency(lastItem, myNewList));
        setReady(true);
      } else {
        setSelectedCurrency(newValue);
        setReady(true);
      }
    }}

    renderTags={(tagValue, getTagProps) =>
      tagValue.map((currency, index) => (
        <Chip
          label={currency.nombre + ' (' + currency.nombre_corto + ')'}
          {...getTagProps({ index })}
          variant="outlined"
          color="primary"
        />
      )
      )
    }

    renderInput={(params) => <TextField
      {...params}
      label={multiple ? "Monedas" : "Moneda"}
      placeholder={multiple ? "Selecciona las monedas" : "Selecciona la moneda"}
    />
    }
  />
}

MonedaPicker.propTypes = {
  onChange: PropTypes.func.isRequired, // Function to be called when there is a change
  currencies: PropTypes.arrayOf(currencyPropType).isRequired, // All available options for currencies
  initialValues: PropTypes.oneOfType([currencyPropType, PropTypes.arrayOf(currencyPropType)]), // Values to initialize selector  
  multiple: PropTypes.bool, // Indicates whether multiple selection is allowed or not
  readOnly: PropTypes.bool, // Indicates whether the component should be read-only or modifiable
}

export default MonedaPicker;