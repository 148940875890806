// React imports
import { useState, useEffect, memo } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI components imports
import { Alert, Button, Typography, Stack, Dialog, DialogContent } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

// My components imports
import LotesDeAccionList from '../../lists/investment/lotesDeAccionList';
import MyLoadingList from '../../basic/myLoadingList';
import { accountPropType, custodyAgentPropType } from '../../../utils/myPropTypes';
import { getStockBatches } from '../../../utils/investmentApi';
import { reformatStockBatches } from '../../../utils/investment/misc';
import CreateModifyLoteDeAccion from './createModifyLoteDeAccion';
import { getCustodyAgents, getUserAccounts } from '../../../utils/api';

function LoteDeAccionManager({ fixedCustodyAgent = null, onChange }) {

  // State constants
  const [accounts, setAccounts] = useState(undefined);
  const [loteDeAccionList, setLoteDeAccionList] = useState(undefined);
  const [custodyAgents, setCustodyAgents] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showBatchForm, setShowBatchForm] = useState(false);

  // Auxiliary functions
  const loadValues = async (fixedAgent) => {
    setLoading(true);
    let newCustodyAgents = [];
    if (!fixedAgent) {
      newCustodyAgents = await getCustodyAgents();
      newCustodyAgents = newCustodyAgents.results.filter(agent => agent.tipo.nombre == "Corredor de bolsa");
    } else {
      newCustodyAgents = [fixedAgent];
    }
    setCustodyAgents(newCustodyAgents);
    Promise.all([getStockBatches({ custodyAgents: newCustodyAgents }), getUserAccounts()])
      .then(responses => {
        /**filter to keep only accounts belonging to newCustodyAgents */
        const filteredAccounts = responses[1].results.filter(account => newCustodyAgents.some(agent => agent.id === account.agente_de_custodia.id));
        setAccounts(filteredAccounts);
        setLoteDeAccionList(reformatStockBatches(responses[0].results));
        setError(false);
      })
      .catch((error) => {
        setError(true);
      });
  }

  // Effects
  useEffect(() => {
    // Initialize values on first load and when custodyAgents change
    let isMounted = true;
    if (isMounted) {
      loadValues(fixedCustodyAgent);
    }
    return () => { isMounted = false }
  }, [fixedCustodyAgent]);

  useEffect(() => {
    // Check if all values are loaded to stop loading
    let isMounted = true;
    if (isMounted && accounts !== undefined && custodyAgents !== undefined && loteDeAccionList !== undefined) {
      setLoading(false);
    }
    return () => { isMounted = false }
  }, [loteDeAccionList, accounts, custodyAgents]);

  // Handlers
  const handleListUpdateRequest = () => {
    setShowBatchForm(false);
    loadValues(fixedCustodyAgent);
    onChange && onChange();
  }

  return error ?
    <Alert severity="error">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body">
          Error cargando información sobre acciones y ETFs
        </Typography>
        <Button variant="outlined" onClick={() => window.location.reload()} fullWidth>Recargar <ReplayIcon /></Button>
      </Stack>
    </Alert> :
    (loading ?
      <MyLoadingList /> :
      <Stack>
        <Dialog
          onClose={() => setShowBatchForm(false)}
          open={showBatchForm}
        >
          <DialogContent>
            <CreateModifyLoteDeAccion
              intent="create"
              onSubmit={handleListUpdateRequest}
              onCancel={() => setShowBatchForm(false)}
              fixedCustodyAgent={fixedCustodyAgent}
              custodyAgents={custodyAgents}
              accounts={accounts}
              initialLoteDeAccion={null}
            />
          </DialogContent>
        </Dialog>
        <Stack spacing={1}>
          {custodyAgents.length > 0 &&
            <LotesDeAccionList
              lotesDeAccionList={loteDeAccionList}
              onUpdate={handleListUpdateRequest}
              fixedCustodyAgent={fixedCustodyAgent}
              custodyAgents={custodyAgents}
              accounts={accounts}
              onRegisterClick={() => setShowBatchForm(true)}
            />}
        </Stack>
      </Stack>)

}

// PropTypes
LoteDeAccionManager.propTypes = {
  accounts: PropTypes.arrayOf(accountPropType).isRequired,
  fixedCustodyAgent: custodyAgentPropType,
  onChange: PropTypes.func,  // Callback to parent component
}

export default memo(LoteDeAccionManager);

