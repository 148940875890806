// React imports
import { useState, useEffect } from 'react';

// Proptypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Alert, Button, Stack, TextField, Typography } from '@mui/material';

// My component imports
import AccionPicker from '../../inputs/investment/accionPicker';
import EasyDatePicker from '../../inputs/easyDatePicker';
import CuentaPicker from '../../inputs/cuentaPicker';
import CustodyAgentPicker from '../../inputs/custodyAgentPicker';
import MontoInput from '../../inputs/montoInput';
import { getDateFromDateTime } from '../../../utils/date';
import { accountPropType, custodyAgentPropType } from '../../../utils/myPropTypes';

function LoteDeAccionForm({ initialLoteDeAccion, custodyAgents, fixedCustodyAgent, accounts: allAccounts, intent, onSubmit, onCancel }) {

  // State constants
  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedDate, setSelectedDate] = useState(initialLoteDeAccion ? initialLoteDeAccion.fecha : getDateFromDateTime(new Date()));
  const [selectedQuantity, setSelectedQuantity] = useState(initialLoteDeAccion ? initialLoteDeAccion.cantidad : 0);
  const [selectedPrice, setSelectedPrice] = useState(initialLoteDeAccion ? initialLoteDeAccion.precio : 0);
  const [selectedCustodyAgent, setSelectedCustodyAgent] = useState(fixedCustodyAgent ? fixedCustodyAgent : (initialLoteDeAccion ? initialLoteDeAccion.agente_de_custodia : null));
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [disableRegisterButton, setDisableRegisterButton] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [accountNotFoundError, setAccountNotFoundError] = useState(false);

  // Effects

  // Effect to update accounts when selectedCustodyAgent or allAccounts change
  useEffect(() => {
    let isMounted = true;
    if (isMounted && allAccounts && allAccounts.length > 0) {
      if (selectedCustodyAgent) {
        setLoadingAccounts(true);
        const newAccountsValues = allAccounts.filter(account => account.agente_de_custodia.id == selectedCustodyAgent.id && account.moneda.nombre_corto == "USD");
        if (newAccountsValues.length === 0) {
          // No account complies with belonging to custody agent and be a USD account. Show error.
          setAccountNotFoundError(true);
          setLoadingAccounts(false);
          setAccounts([]);
        } else {
          // Everithing is OK, set available accounts and selected account
          setAccounts(newAccountsValues);
          setAccountNotFoundError(false);
          setLoadingAccounts(false);
          const newSelectedAccountFromInitialLoteDeAccion = initialLoteDeAccion && initialLoteDeAccion.cuenta ? newAccountsValues.find(acct => acct.id === initialLoteDeAccion.cuenta.id) : null;
          if (newSelectedAccountFromInitialLoteDeAccion) {
            setSelectedAccount(newSelectedAccountFromInitialLoteDeAccion);
          } else {
            setSelectedAccount(newAccountsValues[0]);
          }
        }
      } else if (isMounted && !selectedCustodyAgent && allAccounts) {
        // Custody agent is not selected, there is no error in accounts, but we don't know what accounts should be available either
        setAccounts([]);
        setLoadingAccounts(true);
        setAccountNotFoundError(false);
      }
    } else {
      // There are no available accounts, show error
      setAccounts([]);
      setLoadingAccounts(false);
      setAccountNotFoundError(true);
    }
    return () => isMounted = false;
  }, [selectedCustodyAgent, allAccounts]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && initialLoteDeAccion && initialLoteDeAccion.accion) {
      setSelectedStock(initialLoteDeAccion.accion);
    }
    return () => isMounted = false;
  }, [initialLoteDeAccion]);

  // Effect to update selectedCustodyAgent when fixedCustodyAgent changes
  useEffect(() => {
    setSelectedCustodyAgent(fixedCustodyAgent ? fixedCustodyAgent : (initialLoteDeAccion ? initialLoteDeAccion.agente_de_custodia : null));
  }, [fixedCustodyAgent, initialLoteDeAccion]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (selectedStock && selectedDate && selectedQuantity > 0 && selectedAccount) {
        setDisableRegisterButton(false);
      } else {
        setDisableRegisterButton(true);
      }
    }
    return () => { isMounted = false }
  }, [selectedStock, selectedDate, selectedQuantity, selectedPrice, selectedAccount]);

  // Handlers
  const handleSelectedCustodyAgentChange = agent => {
    setLoadingAccounts(true);
    setAccountNotFoundError(false);
    setSelectedCustodyAgent(agent);
  }

  const handleSelectedAccountChange = acct => {
    setSelectedAccount(acct);
  }

  const handleSubmit = () => {
    setDisableRegisterButton(true);
    onSubmit({
      id: initialLoteDeAccion ? initialLoteDeAccion.id : null,
      stock: selectedStock,
      date: selectedDate,
      quantity: selectedQuantity,
      price: selectedPrice,
      account: selectedAccount,
      custodyAgent: selectedCustodyAgent,
    });
  }

  return accountNotFoundError ?
    <Stack alignItems={"center"} spacing={1}>
      <Alert severity="error">
        <Stack spacing={1}>
          <Typography variant="body">No se encontraron cuentas en dólares en el agente de custodia seleccionado</Typography>
          <Button variant="contained" onClick={onCancel}>Cancelar</Button>
        </Stack>
      </Alert>
    </Stack>
    :
    <Stack spacing={1}>
      <AccionPicker
        initialAccionList={[]}
        onSelect={setSelectedStock}
        initialSelectedStock={selectedStock}
        disabled={initialLoteDeAccion ? true : false}
      />

      <EasyDatePicker
        helperValues={['hoy', 'ayer', 'antesDeAyer']}
        onChange={setSelectedDate}
        initialValue={selectedDate}
      />

      <CustodyAgentPicker
        selectedAgents={selectedCustodyAgent}
        agents={fixedCustodyAgent ? [fixedCustodyAgent] : custodyAgents.filter(agent => agent.tipo.nombre === "Corredor de bolsa")}
        multiple={false}
        onChange={handleSelectedCustodyAgentChange}
        label="Agente de custodia"
        disabled={fixedCustodyAgent ? true : false}
      />

      <TextField
        label="Cantidad"
        value={selectedQuantity}
        onChange={(e) => setSelectedQuantity(e.target.value)}
        type="number"
        placeholder="Introduce la cantidad de acciones"
      />
      {
        selectedQuantity > 0 && selectedPrice !== 0 &&
        <Typography variant="caption" fontStyle={"italic"} sx={{ paddingLeft: "5px" }}>
          Precio por acción: {(selectedPrice / selectedQuantity).toFixed(2)}
        </Typography>
      }
      <MontoInput
        label="Precio"
        ammount={selectedPrice}
        onChange={setSelectedPrice}
        placeholder="Introduce el precio de las acciones"
        isExpense={initialLoteDeAccion ? initialLoteDeAccion.precio <= 0 : true}
        allowSwitching={true}
        switchingText={{ ingresoText: "Cambiar a venta", egresoText: "Cambiar a compra" }}
      />

      {!loadingAccounts &&
        <CuentaPicker
          accounts={accounts}
          multiple={false}
          onChange={handleSelectedAccountChange}
          initialValues={selectedAccount}
          label="Cuenta"
        />
      }

      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={disableRegisterButton}>Registrar</Button>
      <Button variant="contained" color="secondary" onClick={onCancel}>Cancelar</Button>
    </Stack>
}

// PropTypes
LoteDeAccionForm.propTypes = {
  initialLoteDeAccion: PropTypes.object,
  custodyAgents: PropTypes.arrayOf(custodyAgentPropType).isRequired,
  intent: PropTypes.oneOf(['create', 'modify']),
  fixedCustodyAgent: custodyAgentPropType, // Optional, if provided the form allows only batches stored in this custody agent and the account picker will only show accounts from this custody agent
  accounts: PropTypes.arrayOf(accountPropType).isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

export default LoteDeAccionForm;