// React imports
import { useState, useEffect } from "react";

// MUI imports
import { Alert, Skeleton, Stack } from "@mui/material";

// My components imports
import PortafolioSummaryVisualization from "../../basic/investment/portafolioSummaryVisualization";
import { getPortfolioSummary } from "../../../utils/investmentApi";


function PorfolioSummary() {

  // Auxiliary functions
  const updatePortfolioSummary = () => {
    setLoadingPortfolioSummary(true);
    getPortfolioSummary()
      .then(response => {
        if (response.code && response.code === 404) { throw new Error("No se encontró el resumen del portafolio") }
        setPortfolioSummary(response);
        setLoadingPortfolioSummary(false);
        setPortafolioError(false);
      })
      .catch((error) => {
        setPortafolioError(true);
        setLoadingPortfolioSummary(false);
        console.error(error);
      });
  }

  // State hooks
  const [portfolioSummary, setPortfolioSummary] = useState({});
  const [loadingPortfolioSummary, setLoadingPortfolioSummary] = useState(true);
  const [portafolioError, setPortafolioError] = useState(false);

  // Effects
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      updatePortfolioSummary();
    }
    return () => {
      isMounted = false;
    }
  }, []);

  return portafolioError ?
    <Alert severity="info">No hay activos aún para formar un portafolio</Alert> :
    loadingPortfolioSummary ?
      <Stack spacing={0.5}>
        <Skeleton variant="rounded" width="100%" height={30} />
        <Skeleton variant="rounded" width="100%" height={30} />
      </Stack>
      :
      <PortafolioSummaryVisualization
        currentValue={portfolioSummary.current_value}
        currentCost={portfolioSummary.current_cost}
        unrealizedGains={portfolioSummary.unrealized_gains}
        realizedGains={portfolioSummary.realized_gains}
        totalCost={portfolioSummary.total_cost}
      />

}

export default PorfolioSummary;