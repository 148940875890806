// React imports
import { useState, useMemo } from "react";

// React Router imports
import { useHistory } from "react-router-dom";

// MUI imports
import Stack from '@mui/material/Stack';
import { Paper, Typography } from "@mui/material";

// My components imports
import { urls } from "../../../settings.js";
import MyMenuComponent from "../myMenuComponent.js";
import LoteDeAccionManager from "./loteDeAccionManager.js";
import MyColumnStack from "../../basic/myColumnStack.js";
import PorfolioSummary from "./portfolioSummary.js";


function PortfolioPage(props) {

  // State constants

  // Other constants
  const history = useHistory();
  const fixedCustodyAgent = useMemo(() => null, []);

  // State constants
  const [updatePortfolioSummary, setUpdatePortfolioSummary] = useState(true);

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.investmentsUrl, name: 'Inversiones' },
    ]}
    currentPageName={'Portafolio'}
  >
    <MyColumnStack spacing={1} alignItems="center">
      <Paper sx={{ width: "100%", padding: "10px" }}>
        <PorfolioSummary />
      </Paper>
      <Paper sx={{ width: "100%", padding: "10px" }}>
        <Stack spacing={1}>
          <Typography variant="body1" fontWeight={"bold"} color="primary">Accciones y ETFs</Typography>
          <LoteDeAccionManager onChange={() => setUpdatePortfolioSummary(prev => !prev)} fixedCustodyAgent={fixedCustodyAgent} />
        </Stack>
      </Paper>
    </MyColumnStack>
  </MyMenuComponent >


}

export default PortfolioPage;