// React imports
import { useState } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Divider, Stack, TextField } from '@mui/material';

// My components imports
import AgendaListItem from '../basic/agendaListItem';
import { agendaPropType } from '../../utils/myPropTypes';

// Auxiliary functions
function createAgendaFilter(textFilter) {
  // Normaliza un texto eliminando tildes y haciendo minúsculas
  const normalizar = texto => texto
    .normalize('NFD') // Descompone caracteres diacríticos
    .replace(/[\u0300-\u036f]/g, '') // Elimina diacríticos
    .toLowerCase(); // Convierte a minúsculas

  // Normaliza el texto del filtro
  const filtroNormalizado = normalizar(textFilter);

  // Retorna una función de filtro para usar con Array.prototype.filter
  return agenda => {
    const nombreNormalizado = normalizar(agenda.nombre);
    return nombreNormalizado.includes(filtroNormalizado);
  };
}

function AgendaList({ agendas, onDelete, onModify }) {

  // State constants
  const [filterText, setFilterText] = useState('');

  // Handlers
  const handleFilterChange = (event) => {
    event.preventDefault();
    setFilterText(event.target.value);
  }

  return (
    <Stack spacing={2}>
      <TextField label="Buscar" variant="outlined" value={filterText} onChange={handleFilterChange} />
      {agendas.filter(createAgendaFilter(filterText)).map((agenda, index, filteredList) => (
        <Stack spacing={2}>
          <AgendaListItem key={agenda.id} agenda={agenda} onDelete={onDelete} onModify={onModify} />
          {/* Add divider only if it is not the last element, contemplate the possibility of the list being filtered*/}
          {index < filteredList.length - 1 && <Divider />}
        </Stack>
      ))}
    </Stack>
  );
}

AgendaList.propTypes = {
  agendas: PropTypes.arrayOf(agendaPropType).isRequired,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
};

export default AgendaList;
