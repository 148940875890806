// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI component improts
import { Stack } from "@mui/system";

// My component imports
import MyPaper from "../basic/myPaper.js";
import { deleteMovement, getCategories, getUserAccounts } from "../../utils/api.js";
import AddButton from "../basic/addButton.js";
import RegisterMovement from "./registerMovement.js";
import MovimientoListItem from "../basic/movimientoListItem.js";
import MyLoading from "../basic/MyLoading.js";
import { emptyMovement, SUCCESS_FEEDBACK_TIMER } from "../../utils/constants.js";
import { AccountError } from "../basic/errors.js";
import MyLoadingList from "../basic/myLoadingList.js";

// Auxiliary functions
function updateInitialValues(newMov, intent, accounts, categories) {
  return {
    initialMovement: intent === 'create' ? emptyMovement : newMov,
    intent: intent,
    accounts: accounts,
    categories: categories,
  }
}

function RegisterMovementPage(props) {

  // Constants
  const emptyInitialValues = {
    initialMovement: emptyMovement,
    intent: 'create',
    categories: [],
    accounts: [],
  }

  // State constants
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const [accounts, setAccounts] = useState(null);
  const [categories, setCategories] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    // Initial data fetching
    Promise.all([getUserAccounts(props.isExpense ? 'registrarGasto' : 'registrarIngreso'), getCategories()])
      .then(response => {
        if (isMounted) {
          setAccounts(response[0].results);
          setCategories(response[1].results);
        }
      })
      .catch(err => {
        setError(true);
        console.log(err)
      });
    return () => { isMounted = false }
  }, []);

  useEffect(() => {
    let isMounted = true;
    // Stop loading when accounts and categories are loaded
    if (accounts && categories && isMounted) {
      if (accounts.length === 0 || categories.length === 0) {
        // There are no accounts or categories, show error
        setError(true);
      } else {
        // Everything is OK, stop loading
        setInitialLoading(false);
      }
    }
    return () => isMounted = false;
  }, [accounts, categories])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && initialValues.intent === 'create') {
      setShowForm(true);
    } else if (isMounted && initialValues.intent === 'modify') {
      setShowForm(false);
    }
    return () => isMounted = false;
  }, [initialValues])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !loadingDelete) {
      setShowDeleteSuccess(false);
    }
  }, [loadingDelete])

  // Handlers
  const handleMovementChange = mov => {
    const newInitialValues = updateInitialValues(mov, 'modify', accounts, categories);
    setInitialValues(newInitialValues);
  }

  const handleNewMovementClick = event => {
    const newInitialValues = updateInitialValues(emptyMovement, 'create', accounts, categories);
    setInitialValues(newInitialValues);
  }

  const handleModifyClick = mov => {
    setShowForm(true);
  }

  const handleDelete = () => {
    setLoadingDelete(true);
    deleteMovement(initialValues.initialMovement.id)
      .then(response => {
        setShowDeleteSuccess(true);
        setTimeout(() => {
          setLoadingDelete(false);
          setInitialValues(emptyInitialValues);
        }, [SUCCESS_FEEDBACK_TIMER]);
      })
      .catch(err => console.log(err));
  }

  return error ? <AccountError /> :
    initialLoading ? <MyPaper><MyLoadingList /></MyPaper> :
      showForm ?
        <MyPaper>
          <RegisterMovement
            initialValues={initialValues}
            accounts={accounts}
            categories={categories}
            onSubmit={handleMovementChange}
            isExpense={initialValues.intent === "modify" ? initialValues.initialMovement.monto < 0 : props.isExpense}
            onCancel={() => setShowForm(false)}
            allowSwitching={props.allowSwitching}
          />
        </MyPaper> :
        <Stack>
          <MyPaper>
            {initialValues.initialMovement.cuenta ? (
              loadingDelete ?
                <Stack alignItems="center"><MyLoading success={showDeleteSuccess} /></Stack> :
                <MovimientoListItem
                  onDelete={handleDelete}
                  onModify={handleModifyClick}
                  movimiento={initialValues.initialMovement}
                />
            ) : <></>}
          </MyPaper>
          <AddButton onClick={handleNewMovementClick} />
        </Stack>
}

RegisterMovementPage.propTypes = {
  isExpense: PropTypes.bool, // Value that indicates whether user is registering an expense or an income
  allowSwitching: PropTypes.bool, // Indicates if the user is allowed to switch between expense and income
}

export default RegisterMovementPage;