// React imports
import { useState, useEffect } from 'react';
import react from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Alert, Stack, Button, Typography, Dialog, DialogContent, TextField, Collapse, Checkbox } from '@mui/material';
// import Checkbox from '@mui/material/Checkbox';
import ReplayIcon from '@mui/icons-material/Replay';

// My components imports
import { accountPropType, custodyAgentPropType, loteDeAccionPropType } from '../../../utils/myPropTypes';
import { getStockLastQuote, deleteStockBatch } from '../../../utils/investmentApi';
import { calculateStockHoldingsValues } from '../../../utils/investment/misc';
import SortBar from '../../basic/investment/sortBar';
import AccionPortafolioListItem from '../../basic/investment/accionPortafolioListItem';
import AccionPortafolioSummary from '../../basic/investment/accionPortafolioSummary';
import CreateModifyLoteDeAccion from '../../app/investment/createModifyLoteDeAccion';
import { getDateFromDateTime } from '../../../utils/date';
import MyLoadingList from '../../basic/myLoadingList';


function sortBatchList(list, criteria, ascending, stockQuotesObject) {
  if (criteria === "monto") {
    return list.sort((a, b) => {
      const aMonto = parseFloat(calculateStockHoldingsValues(a, stockQuotesObject[a.accion.ticker]).currentValue);
      const bMonto = parseFloat(calculateStockHoldingsValues(b, stockQuotesObject[b.accion.ticker]).currentValue);
      if (ascending) return aMonto - bMonto;
      return bMonto - aMonto;
    });
  }
  if (criteria === "ganancia") {
    return list.sort((a, b) => {
      const aMonto = parseFloat(calculateStockHoldingsValues(a, stockQuotesObject[a.accion.ticker]).unrealizedGains);
      const bMonto = parseFloat(calculateStockHoldingsValues(b, stockQuotesObject[b.accion.ticker]).unrealizedGains);
      if (ascending) return aMonto - bMonto;
      return bMonto - aMonto;
    });
  }
  if (criteria === "ganancia porcentual") {
    return list.sort((a, b) => {
      const aMonto = parseFloat(calculateStockHoldingsValues(a, stockQuotesObject[a.accion.ticker]).unrealizedGainsPercentage);
      const bMonto = parseFloat(calculateStockHoldingsValues(b, stockQuotesObject[b.accion.ticker]).unrealizedGainsPercentage);
      if (ascending) return aMonto - bMonto;
      return bMonto - aMonto;
    });
  }
  return list;
}


function LotesDeAccionList({ lotesDeAccionList, onUpdate, custodyAgents, accounts, onRegisterClick, fixedCustodyAgent }) {

  // State constants
  const [stockQuotesObject, setStockQuotesObject] = useState({});
  const [sortedList, setSortedList] = useState([]);
  const [updateList, setUpdateList] = useState(false);
  const [error, setError] = useState(false);
  const [editBatchDialogOpen, setEditBatchDialogOpen] = useState(false);
  const [currentIntent, setCurrentIntent] = useState("modify");
  const [batchToEdit, setBatchToEdit] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showClosedPositions, setShowClosedPositions] = useState(false);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (lotesDeAccionList === undefined || lotesDeAccionList.length === 0) return;
      let quotePromises = lotesDeAccionList.map(item => getStockLastQuote(item.accion));
      Promise.all(quotePromises)
        .then(responses => {
          let quoteObject = {};
          responses.forEach((response, index) => {
            quoteObject[response.accion.ticker] = response;
          });
          setStockQuotesObject(quoteObject);
          setError(false);
        })
        .catch((error) => {
          console.error(error);
          setError(true);
        });
    }
    return () => { isMounted = false }
  }, [lotesDeAccionList]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && lotesDeAccionList !== undefined && lotesDeAccionList.length === 0) {
      setLoading(false);
    } else if (isMounted && lotesDeAccionList.length > 0 && Object.keys(stockQuotesObject).length > 0) {
      setLoading(false);
      setSortedList(sortBatchList(lotesDeAccionList, "monto", true, stockQuotesObject));
      setUpdateList(prev => !prev);
    } else {
      setLoading(true);
    }
    return () => isMounted = false;
  }, [lotesDeAccionList, stockQuotesObject])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && batchToEdit) {
      setEditBatchDialogOpen(true);
    } else {
      setEditBatchDialogOpen(false);
    }
    return () => isMounted = false;
  }, [batchToEdit])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !editBatchDialogOpen && batchToEdit) {
      setBatchToEdit(null);
    }
    return () => isMounted = false;
  }, [editBatchDialogOpen])

  // Handlers
  const handleSortChange = newSortObject => {
    const newSortedList = sortBatchList(lotesDeAccionList, newSortObject.sortBy, newSortObject.sortAscending, stockQuotesObject);
    setSortedList(newSortedList);
    setUpdateList(prev => !prev);
  }

  const handleBatchEdit = batch => {
    setCurrentIntent("modify");
    setBatchToEdit(batch);
  }

  const handleBatchEditCancel = () => {
    setEditBatchDialogOpen(false);
  }

  const handleBatchDelete = batch => {
    deleteStockBatch(batch.id)
      .then(response => {
        onUpdate();
      })
      .catch(error => {
        console.error("Error deleting batch:", error);
      });
  }

  const handleBatchCreate = stock => {
    setCurrentIntent("create");
    setBatchToEdit({
      accion: stock,
      fecha: getDateFromDateTime(new Date()),
      cantidad: 0,
      precio: 0,
      cuenta: null,
      agente_custodia: fixedCustodyAgent,
    });
  }

  const handleBatchUpdated = () => {
    setEditBatchDialogOpen(false);
    onUpdate();
  }

  const handleSummaryClick = () => {
    setShowList(prev => !prev);
  }

  return <Stack spacing={1}>
    {error ? <Alert severity="error">
      <Stack direction="row" spacing={1}>
        <Typography variant="body">Hubo un error al cargar los datos</Typography>
        <Button variant="outlined" fullWidth onClick={() => window.location.reload()}>Recargar <ReplayIcon /></Button>
      </Stack>
    </Alert> :
      <Stack>
        <Dialog open={editBatchDialogOpen} onClose={handleBatchEditCancel}>
          <DialogContent>
            {editBatchDialogOpen && <CreateModifyLoteDeAccion
              intent={currentIntent}
              initialLoteDeAccion={batchToEdit}
              custodyAgents={custodyAgents}
              accounts={accounts}
              onSubmit={handleBatchUpdated}
              onCancel={handleBatchEditCancel}
              fixedCustodyAgent={fixedCustodyAgent}
            />}
          </DialogContent>
        </Dialog>
        {loading ? <MyLoadingList /> :
          <Stack spacing={0.5}>
            <Stack spacing={1}>
              <AccionPortafolioSummary
                portafolio={lotesDeAccionList}
                moneda={{ "nombre_corto": "USD", "nombre": "Dólar estadounidense" }}
                quoteObject={stockQuotesObject}
                onClick={handleSummaryClick}
              />
            </Stack>
            <Collapse in={showList}>
              <Stack spacing={0.5}>
                {/**Show the checkbox only if there is at least one stock for which the position is closed */}
                {lotesDeAccionList.filter(batch => calculateStockHoldingsValues(batch, stockQuotesObject[batch.accion.ticker]).numberOfStocks === 0).length > 0 &&
                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                    <Checkbox
                      sx={{ paddingLeft: 0 }}
                      checked={showClosedPositions}
                      onChange={event => setShowClosedPositions(event.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography variant="subtitle" color="primary" fontStyle="italic">Mostrar posiciones cerradas</Typography>
                  </Stack>
                }
                <TextField label="Filtrar" variant="outlined" value={filterText} onChange={e => setFilterText(e.target.value)} fullWidth />
                <Stack spacing={0.2}>
                  <SortBar onChange={handleSortChange} initialSortBy={"monto"} initiallySortAscending />
                  {sortedList
                    .filter(batch => batch.accion.nombre.toLowerCase().includes(filterText.toLowerCase()) || batch.accion.ticker.toLowerCase().includes(filterText.toLowerCase()))
                    .filter(batch => showClosedPositions ? true : calculateStockHoldingsValues(batch, stockQuotesObject[batch.accion.ticker]).numberOfStocks !== 0)
                    .map((accion, index) => {
                      return <AccionPortafolioListItem
                        key={index}
                        accion={accion}
                        moneda={{ "nombre_corto": "USD", "nombre": "Dólare estadounidense" }}
                        currentQuote={stockQuotesObject[accion.accion.ticker]}
                        onBatchDelete={batch => handleBatchDelete(batch)}
                        onBatchEdit={batch => handleBatchEdit(batch)}
                        onBatchCreate={handleBatchCreate}
                      />
                    })}
                </Stack>
              </Stack>
            </Collapse>
            <Button variant="outlined" onClick={onRegisterClick} fullWidth>Registrar compra / venta</Button>
          </Stack>
        }
      </Stack>}
  </Stack>
}

// PropTypes
LotesDeAccionList.propTypes = {
  lotesDeAccionList: PropTypes.arrayOf(loteDeAccionPropType).isRequired,
  accounts: PropTypes.arrayOf(accountPropType).isRequired,
  onUpdate: PropTypes.func.isRequired, // Function to be called when the list needs to be updated
  custodyAgents: PropTypes.arrayOf(custodyAgentPropType).isRequired,
  onRegisterClick: PropTypes.func.isRequired,
  fixedCustodyAgent: custodyAgentPropType, // Custody agent to be used for all the batches
}

export default LotesDeAccionList;