// React imports
import { useState, useEffect, useRef } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Typography } from '@mui/material';
import { CardContent, Stack, CircularProgress } from '@mui/material';

// My components imports
import EasyDateRangePicker from '../inputs/easyDateRangePicker.js';
import ConfirmationDialog from '../basic/confirmationDialog.js';
import MyPaper from '../basic/myPaper.js';
import MonedaPicker from '../inputs/monedaPicker.js';
import { apiDateParser, getDateFromDateTime } from '../../utils/date.js';
import CustodyAgentPicker from '../inputs/custodyAgentPicker.js';
import { getDate } from 'date-fns';

function CardForm(props) {

  // Other constants
  const inputRef = useRef(null);

  // State constants
  const [selectedCurrency, setSelectedCurrency] = useState(props.card.moneda ? props.card.moneda : props.currencies.find(curr => curr.nombre_corto === 'UYU'));
  const [name, setName] = useState(props.card ? props.card.nombre : '');
  const [number, setNumber] = useState(props.card ? props.card.numero : '');
  const [selectedAgent, setSelectedAgent] = useState(props.card ? props.card.agente_de_custodia : null);
  // for closeDate default is first day of current month
  const [closeDate, setCloseDate] = useState(props.card ? props.card.fecha_de_cierre_actual : getDateFromDateTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
  // for dueDate default is today
  const [dueDate, setDueDate] = useState(props.card ? props.card.fecha_de_vencimiento_actual : getDateFromDateTime(new Date()));
  const [creditLimit, setCreditLimit] = useState(props.card ? props.card.tope_de_credito : 0);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [cardBalanceEditing, setCardBalanceEditing] = useState(0);
  const [showBalanceEditForm, setShowBalanceEditForm] = useState(false);
  const [balance, setBalance] = useState(0);
  const [updatingBalance, setUpdatingBalance] = useState(false);
  const [canUseBlur, setCanUseBlur] = useState(false);

  // Effect hooks
  useEffect(() => {
    if (showBalanceEditForm) {
      if (inputRef.current) {
        inputRef.current.focus();
        setCanUseBlur(true);
      }
    }
  }, [showBalanceEditForm, inputRef.current]);

  // Change handlers
  const handleNameChange = event => setName(event.target.value);
  const handleNumberChange = event => setNumber(event.target.value);
  const handleDateChange = (newCloseDate, newDueDate) => {
    setCloseDate(newCloseDate);
    setDueDate(newDueDate);
  }
  const handleCreditLimitChange = event => setCreditLimit(event.target.value);
  const handleCardBalanceClick = (saldo) => {
    setShowConfirmationDialog(true);
    setBalance(saldo.balance);
    setCardBalanceEditing(saldo.id);
  }
  const handleBalanceTyping = event => setBalance(event.target.value)
  const handleBalanceChange = (saldo, newBalance) => {
    console.log("handleBalanceChange running...");
    setUpdatingBalance(true);
    props.onBalanceChange(saldo, newBalance)
      .then(result => {
        setCardBalanceEditing(0);
        setUpdatingBalance(false);
        setShowBalanceEditForm(false);
        setCanUseBlur(false);
      })
      .catch(err => console.log(err));
  }

  const handleAgentChange = (agent) => {
    setSelectedAgent(agent)
  };

  const handleConfirm = () => {
    if (props.intent === 'create') {
      props.onSubmit(name, closeDate, dueDate, selectedCurrency ? selectedCurrency.id : null, number, creditLimit, selectedAgent ? selectedAgent.id : null);
    } else if (props.intent === 'modify') {
      props.onSubmit(name, closeDate, dueDate, selectedCurrency ? selectedCurrency.id : null, number, creditLimit, selectedAgent ? selectedAgent.id : null, props.card.id);
    }
  };

  return <Stack spacing={1}>
    {showConfirmationDialog ?
      <ConfirmationDialog
        open={showConfirmationDialog}
        cancelButtonText="Cancelar"
        confirmButtonText="Estoy seguro"
        text="¡Atención!: Solo cambia el saldo de tu tarjeta si estas seguro de lo que haces"
        onClose={() => {
          setShowConfirmationDialog(false);
          setShowBalanceEditForm(false);
        }}
        onConfirm={() => {
          setShowBalanceEditForm(true);
          setShowConfirmationDialog(false);
        }}
      /> :
      <></>
    }

    <Stack direction="row" spacing={1} sx={{ marginBottom: "5px" }}>
      {props.card.saldos_de_tarjeta.length > 0 ? props.card.saldos_de_tarjeta.map(saldo => {
        return <MyPaper key={saldo.id}>
          {cardBalanceEditing === saldo.id && showBalanceEditForm ? (
            updatingBalance ? <CircularProgress sx={{ marginLeft: "100px", marginRight: "100px" }} /> :
              <CardContent>
                <FormControl sx={{ maxWidth: "200px" }} fullWidth>
                  <InputLabel htmlFor={`balance-en-${saldo.moneda.nombre_corto}`}>Saldo en {saldo.moneda.nombre_corto}</InputLabel>
                  <FilledInput
                    id={`saldo-en-${saldo.moneda.nombre_corto}`}
                    value={balance}
                    onChange={handleBalanceTyping}
                    variant="filled"
                    type="number"
                    autoFocus={true}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label={`Saldo en ${saldo.moneda.nombre_corto}`}
                    onBlur={() => {
                      if (canUseBlur) {
                        handleBalanceChange(saldo, balance);
                      }
                    }}
                    inputRef={inputRef}
                  />
                </FormControl>
              </CardContent>
          ) :
            <Stack onClick={() => handleCardBalanceClick(saldo)} alignItems="stretch">
              <Typography variant="body2" color="text.secondary">{"Saldo en " + saldo.moneda.nombre_corto}:</Typography>
              <Typography color="text.secondary">$ {saldo.balance.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}</Typography>
            </Stack>
          }
        </MyPaper>
      }) : <></>
      }
    </Stack>

    <FormControl fullWidth>
      <CustodyAgentPicker
        selectedAgents={selectedAgent}
        onChange={handleAgentChange}
        agents={props.agents}
        multiple={false}
        disabled={props.intent === 'modify'}
      />
    </FormControl>

    <FormControl fullWidth>
      <TextField
        id="name"
        label="Nombre"
        variant="filled"
        value={name}
        onChange={handleNameChange}
      />
    </FormControl>

    <FormControl fullWidth>
      <TextField
        id="number"
        label="Número"
        variant="filled"
        value={number}
        onChange={handleNumberChange}
      />
    </FormControl>

    <FormControl fullWidth>
      <EasyDateRangePicker
        helperValues={[]}
        strict={true}
        onChange={handleDateChange}
        initialValues={{ initialDate: closeDate, finalDate: dueDate }}
        initialLabel="Fecha de cierre"
        finalLabel="Fecha de vencimiento"
        initialDateDisabled={props.card.id ? true : false}
        finalDateDisabled={props.card.id ? true : false}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </FormControl>

    <FormControl fullWidth>
      <MonedaPicker
        currencies={props.currencies}
        onChange={newCurr => setSelectedCurrency(newCurr)}
        initialValues={selectedCurrency}
        multiple={false}
        readOnly={props.intent === 'modify'}
      />
    </FormControl>

    <FormControl fullWidth>
      <InputLabel htmlFor="limite-de-credito">Límite de crédito</InputLabel>
      <FilledInput
        id="limite-de-credito"
        value={creditLimit}
        onChange={handleCreditLimitChange}
        variant="filled"
        type="number"
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        label="Límite de crédito"
      />
    </FormControl>

    <Button variant="contained" onClick={handleConfirm} disabled={!selectedAgent || !selectedCurrency} fullWidth>
      {props.intent === 'create' ? 'Crear tarjeta' : 'Modificar tarjeta'}
    </Button>

    <Button variant="contained" color="cancel" onClick={() => props.onCancel()} fullWidth>Volver</Button>
  </Stack>

}


CardForm.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string,
    numero: PropTypes.string,
    fecha_de_cierre_actual: PropTypes.string,
    fecha_de_vencimiento_actual: PropTypes.string,
    agente_de_custodia: PropTypes.object,
    tope_de_credito: PropTypes.number,
    moneda: PropTypes.object,
    cuenta: PropTypes.object,
  }),
  intent: PropTypes.string.isRequired,
  onSubmit: PropTypes.func, // Function to be called when Create/Modify button is clicked by user
  onCancel: PropTypes.func, // Function to be called when the user cancel
  onBalanceChange: PropTypes.func, // Function to be called when a card balance is modified
  minDate: PropTypes.instanceOf(Date), // Minimum date for close and due dates
  maxDate: PropTypes.instanceOf(Date), // Maximum date for close and due dates
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available currencies
  agents: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available custody agents
}


export default CardForm;