// React imports
import { useEffect, useState } from 'react';

// Proptypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Typography, Stack } from '@mui/material';
import TouchAppIcon from '@mui/icons-material/TouchApp';

// My component imports
import { accionEnPortafolioPropType, currencyPropType, stockQuotePropType } from '../../../utils/myPropTypes';
import { calculatePortfolioHoldingsValues } from '../../../utils/investment/misc';
import { getAmountText, getPercentageText } from '../../../utils/misc';
import { getAmountDisplayColor } from '../../../utils/misc';


function AccionPortafolioSummary({ portafolio, moneda, quoteObject, onClick }) {

  const [holdingsValues, setHoldingsValues] = useState(calculatePortfolioHoldingsValues(portafolio, quoteObject));
  const [totalHoldingsText, setTotalHoldingsText] = useState(0);
  const [totalGrowthText, setTotalGrowthText] = useState(0);
  const [totalPercentageGrowthText, setTotalPercentageGrowthText] = useState(0);
  const [totalCostText, setTotalCostText] = useState(0);
  const [realizedGainsText, setRealizedGainsText] = useState(0);
  const [realizedGainsPercentageText, setRealizedGainsPercentageText] = useState(0);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setHoldingsValues(calculatePortfolioHoldingsValues(portafolio, quoteObject));
    }
    return () => { isMounted = false };
  }, [portafolio, quoteObject]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && moneda) {
      setTotalHoldingsText(getAmountText(holdingsValues.currentValue, moneda));
      setTotalGrowthText(getAmountText(holdingsValues.unrealizedGains, moneda));
      setTotalCostText(getAmountText(-holdingsValues.currentCost, moneda));
      setTotalPercentageGrowthText(getPercentageText(holdingsValues.unrealizedGainsPercentage));
      setRealizedGainsText(getAmountText(holdingsValues.realizedGains, moneda));
      setRealizedGainsPercentageText(getPercentageText(holdingsValues.realizedGainsPercentage));
    } else {
      setTotalHoldingsText("N/A");
      setTotalGrowthText("N/A");
      setTotalCostText("N/A");
      setTotalPercentageGrowthText("N/A");
      setRealizedGainsText("N/A");
      setRealizedGainsPercentageText("N/A");
    }
    return () => { isMounted = false };
  }, [holdingsValues, moneda]);

  return <Stack direction="column" spacing={0.5} onClick={onClick} sx={{ cursor: "pointer", paddingBottom: "20px" }}>

    <Stack direction="row" sx={{ paddingTop: "10px" }} spacing={1}>
      <TouchAppIcon color='primary' />
      <Stack direction="row" alignItems={"flex-end"}>
        <Typography variant="caption" fontStyle={"italic"} sx={{ marginTop: "7px" }}>(Mostrar / ocultar la lista de acciones y ETFs)</Typography>
      </Stack>
    </Stack>

    <Stack direction="row" sx={{ paddingTop: "10px", paddingBottom: "10px" }} justifyContent={"space-between"} spacing={1}>
      <Typography variant="body1" fontWeight={"bold"}>
        Valor actual:
      </Typography>
      <Typography variant="body1" fontWeight={"bold"}>{totalHoldingsText}</Typography>
    </Stack>

    <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
      <Typography variant="caption" fontStyle={"italic"} sx={{ borderBottom: "none" }}>
        Ganancia no realizada:
      </Typography>
      <Typography variant="caption" color={getAmountDisplayColor(holdingsValues.unrealizedGains)} fontWeight={"bold"}>
        {totalGrowthText + " (" + totalPercentageGrowthText + ")"}
      </Typography>
    </Stack>

    <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
      <Typography variant="caption" fontStyle={"italic"}>
        Ganancia realizada:
      </Typography>
      <Typography variant="caption" color={getAmountDisplayColor(holdingsValues.realizedGains)} fontWeight={"bold"}>
        {realizedGainsText + " (" + realizedGainsPercentageText + ")"}
      </Typography>
    </Stack>

    <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
      <Typography variant="caption" fontStyle={"italic"}>Costo total:</Typography>
      <Typography variant="caption" fontWeight={"bold"}>{totalCostText}</Typography>
    </Stack>

  </Stack >
}

AccionPortafolioSummary.propTypes = {
  portafolio: PropTypes.arrayOf(accionEnPortafolioPropType).isRequired,
  moneda: currencyPropType, // currency in which is nominated the portfolio
  quoteObject: PropTypes.arrayOf(stockQuotePropType).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AccionPortafolioSummary;