// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import { createCard, modifyCard } from "../../utils/api";
import CardForm from '../forms/cardForm.js';

function CreateModifyCard(props) {
  return <CardForm
    card={props.initialValues.initialCard}
    intent={props.initialValues.intent}
    onSubmit={(name, closeDate, dueDate, currencyId, number, creditLimit, custodyAgentId, cardId) => {
      if (props.initialValues.intent === 'create') {
        createCard(name, closeDate, dueDate, currencyId, number, creditLimit, custodyAgentId)
          .then(result => props.onSubmit(result))
          .catch(err => console.log(err))
      } else {
        modifyCard(name, closeDate, dueDate, currencyId, number, creditLimit, custodyAgentId, cardId)
          .then(result => props.onSubmit(result))
          .catch(err => console.log(err))
      }
    }}
    onCancel={props.onCancel}
    onBalanceChange={() => console.log("Balance changed")}
    minDate={new Date()}
    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
    currencies={props.currencies.filter(currency => currency.nombre_corto === "UYU")}
    agents={props.custodyAgents}
  />
}

CreateModifyCard.propTypes = {
  initialValues: PropTypes.shape({
    initialCard: PropTypes.shape({
      id: PropTypes.number,
      nombre: PropTypes.string,
      numero: PropTypes.string,
      fecha_de_cierre_actual: PropTypes.string,
      fecha_de_vencimiento_actual: PropTypes.string,
      agente_de_custodia: PropTypes.object,
      tope_de_credito: PropTypes.number,
      moneda: PropTypes.object,
      cuenta: PropTypes.object,
    }),// Account object to initialize Form in case of modify intent
    intent: PropTypes.oneOf(["create", "modify"]), // Indicates whether the user is trying to create a new one or modify an existing account
    custodyAgent: PropTypes.object, // Optional value, if present, the custody agent will be preselected
  }),
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired, // All available currencies
  custodyAgents: PropTypes.arrayOf(PropTypes.object).isRequired, // All available custody agents
  onSubmit: PropTypes.func.isRequired, // Function to be called when an account is created or modified
  onCancel: PropTypes.func.isRequired, // Function to be called when user cancels the operation
}

export default CreateModifyCard;