// Proptypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Typography, Stack, Divider } from '@mui/material';

// My component imports
import { getAmountDisplayColor, getAmountText, getPercentageText } from '../../../utils/misc';

function PortafolioSummaryVisualization({ currentValue, currentCost, unrealizedGains, realizedGains, totalCost }) {

  return <Stack spacing={1}>
    <Stack spacing={1.5}>
      <Typography variant="h6" color="primary">Resumen del portafolio</Typography>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="body1" fontWeight={"bold"}>Valor actual:</Typography>
        <Typography variant="body1" fontWeight={"bold"}>{getAmountText(currentValue, { nombre_corto: "USD" })}</Typography>
      </Stack>
    </Stack>
    <Divider />
    <Stack spacing={0.5}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="caption" fontStyle={"italic"}>Costo actual:</Typography>
        <Typography variant="caption" fontWeight={"bold"}>{getAmountText(Math.abs(currentCost), { nombre_corto: "USD" })}</Typography>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="caption" fontStyle={"italic"}>Ganancias no realizadas:</Typography>
        <Typography variant="caption" fontWeight={"bold"} color={getAmountDisplayColor(unrealizedGains)}>
          {getAmountText(unrealizedGains, { nombre_corto: "USD" }) + " (" + getPercentageText((-unrealizedGains / currentCost) * 100) + ")"}
        </Typography>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="caption" fontStyle={"italic"}>Ganancias realizadas:</Typography>
        <Typography variant="caption" fontWeight={"bold"} color={getAmountDisplayColor(realizedGains)}>
          {getAmountText(realizedGains, { nombre_corto: "USD" }) + " (" + getPercentageText((-realizedGains / totalCost) * 100) + ")"}
        </Typography>
      </Stack>

    </Stack>
  </Stack>
}

PortafolioSummaryVisualization.propTypes = {
  currentValue: PropTypes.number,
  currentCost: PropTypes.number,
  unrealizedGains: PropTypes.number,
  realizedGains: PropTypes.number,
  totalCost: PropTypes.number,
};

export default PortafolioSummaryVisualization;