// React imports
import { useState, useEffect } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Dialog, DialogContent, Divider, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteIcon from '@mui/icons-material/Delete';


// My components imports
import AccionIcon from './accionIcon';
import { calculateStockHoldingsValues } from '../../../utils/investment/misc';
import { dateToDisplayFormat } from '../../../utils/date';
import { accionPropType, currencyPropType, stockQuotePropType } from '../../../utils/myPropTypes';
import { getAmountDisplayColor, getAmountText, getPercentageText } from '../../../utils/misc';

// Auxiliary functions
function getStockNameToDisplay(stock) {
  if (stock === undefined) return "N/A";
  const MAX_LENGTH = 20;
  let str = stock.nombre;
  if (str.length > MAX_LENGTH) {
    return str.slice(0, MAX_LENGTH) + '...';
  }
  return str;
}

function AccionPortafolioListItem({ accion, moneda, key, currentQuote, onBatchDelete, onBatchEdit, onBatchCreate }) {

  // Constants
  const theme = useTheme();

  // State constants
  const [holdingsValues, setHoldingsValues] = useState(calculateStockHoldingsValues(accion, currentQuote));
  const [totalHoldingsText, setTotalHoldingsText] = useState("N/A");
  const [totalGrowthText, setTotalGrowthText] = useState("N/A");
  const [totalPercentageGrowthText, setTotalPercentageGrowthText] = useState("N/A");
  const [totalCostText, setTotalCostText] = useState("N/A");
  const [lastQuoteText, setLastQuoteText] = useState("N/A");
  const [totalNumberOfStocksText, setTotalNumberOfStocksText] = useState("N/A");
  const [realizedGainsText, setRealizedGainsText] = useState("N/A");
  const [realizedGainsPercentageText, setRealizedGainsPercentageText] = useState("N/A");
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
  const [batchToDelete, setBatchToDelete] = useState(null);

  // Effects
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setHoldingsValues(calculateStockHoldingsValues(accion, currentQuote));
    }
    return () => { isMounted = false };
  }, [accion, currentQuote]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && moneda) {
      setTotalHoldingsText(getAmountText(holdingsValues.currentValue, moneda));
      setTotalGrowthText(getAmountText(holdingsValues.unrealizedGains, moneda));
      setTotalCostText(getAmountText(holdingsValues.currentCost, moneda));
      setTotalPercentageGrowthText(getPercentageText(holdingsValues.unrealizedGainsPercentage));
      setTotalNumberOfStocksText(holdingsValues.numberOfStocks);
      setRealizedGainsText(getAmountText(holdingsValues.realizedGains, moneda));
      setRealizedGainsPercentageText(getPercentageText(holdingsValues.realizedGainsPercentage));
    } else if (isMounted) {
      setTotalHoldingsText("N/A");
      setTotalGrowthText("N/A");
      setTotalCostText("N/A");
      setTotalPercentageGrowthText("N/A");
      setTotalNumberOfStocksText("N/A");
      setRealizedGainsText("N/A");
      setRealizedGainsPercentageText("N/A");
    }
    return () => { isMounted = false };
  }, [holdingsValues])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && currentQuote) {
      const newText = "Última cotización: " + getAmountText(currentQuote.cierre, moneda) + " el " + dateToDisplayFormat(currentQuote.fecha);
      setLastQuoteText(newText);
    }
    return () => { isMounted = false };
  }, [accion, currentQuote]);

  // Handlers
  const handleEditClick = (event, batch) => {
    event.stopPropagation();
    onBatchEdit(batch);
  }

  const handleDeleteClick = (event, batch) => {
    event.stopPropagation();
    setShowDeleteConfirmationDialog(true);
    setBatchToDelete(batch);
  }

  const handleDeleteCancel = event => {
    event.stopPropagation();
    setShowDeleteConfirmationDialog(false);
    setBatchToDelete(null);
  }

  const handleDeleteConfirmation = (event) => {
    event.stopPropagation();
    onBatchDelete(batchToDelete);
  }

  return <Stack key={key} spacing={1}>
    <Dialog open={showDeleteConfirmationDialog} onClose={handleDeleteCancel}>
      <DialogContent>
        <Stack spacing={1}>
          <Typography variant='body'>¿Estas seguro que quieres borrar el lote?</Typography>
          <Stack direction="row" spacing={1}>
            <Button variant="contained" sx={{ backgroundColor: theme.palette.primary.main }} onClick={handleDeleteConfirmation} fullWidth>Si, seguro</Button>
            <Button variant="contained" sx={{ backgroundColor: theme.palette.primary.cancel }} onClick={handleDeleteCancel} fullWidth>No, cancelar</Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
    <Accordion>

      <AccordionSummary>
        <Stack direction="column" spacing={1} sx={{ width: "100%" }}>

          {holdingsValues.numberOfStocks === 0 &&
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Chip label="Posición cerrada" color="warning" size='small' variant="outlined" />
            </Stack>}

          <Stack direction="row" spacing={1} alignItems={"center"}>
            <AccionIcon accion={accion.accion} />
            <Stack>
              <Stack direction="row" spacing={1} alignItems={"center"}>
                <Typography fontWeight="bold">{accion.accion.ticker}</Typography>
                <Typography variant="caption" sx={{ fontStyle: "italic" }}>{getStockNameToDisplay(accion.accion)}</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems={"center"}>
                <Typography variant="body" fontWeight={"bold"}>{totalHoldingsText}</Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack>

            {holdingsValues.numberOfStocks !== 0 && <Stack direction="row" spacing={1} alignItems={"center"}>
              <Typography variant="caption">Ganancia no realizada:</Typography>
              <Typography
                variant="caption"
                sx={{ color: getAmountDisplayColor(holdingsValues.unrealizedGains), fontWeight: "bold" }}
              >
                {totalGrowthText + " (" + totalPercentageGrowthText + ")"}
              </Typography>
            </Stack>}

            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Typography variant="caption">Ganancia realizada:</Typography>
              <Typography
                variant="caption"
                sx={{ color: getAmountDisplayColor(holdingsValues.realizedGains), fontWeight: "bold" }}
              >
                {realizedGainsText}
              </Typography>
              <Typography variant="caption" sx={{ color: getAmountDisplayColor(holdingsValues.realizedGains), fontWeight: "bold" }}>
                {"(" + realizedGainsPercentageText + ")"}
              </Typography>
            </Stack>
            <Typography variant="caption">Total: {totalNumberOfStocksText} acciones</Typography>
            {holdingsValues.numberOfStocks !== 0 &&
              <Typography variant="caption">
                {lastQuoteText}
              </Typography>}
          </Stack>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2} sx={{ cursor: "pointer" }}>
          <Button variant="outlined" onClick={() => onBatchCreate(accion.accion)} fullWidth>Agregar lote de {accion.accion.ticker}</Button>
          {accion.lotes.sort((a, b) => new Date(a.fecha) - new Date(b.fecha)).map((batch, key) => {
            return <Stack key={key} direction="column" spacing={1} sx={{ paddingLeft: "5px" }} onClick={event => handleEditClick(event, batch)}>
              <Divider />
              <Stack direction="row" spacing={1} justifyContent={"space-between"}>
                <Stack direction="column" spacing={1}>
                  <Stack direction={"row"} alignItems="center" spacing={1}>
                    <CalendarMonthIcon color="primary" />
                    <Typography>{dateToDisplayFormat(batch.fecha)}</Typography>
                  </Stack>
                  <Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography fontStyle={"italic"}>{(batch.precio < 0 ? "+ " : "- ") + batch.cantidad + " acciones a"}</Typography>
                      <Typography fontStyle={"italic"} color={getAmountDisplayColor(batch.precio)}>{getAmountText(batch.precio, moneda)}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="caption" fontStyle={"italic"}>Pagadas desde:</Typography>
                      <Typography variant="caption" fontStyle={"italic"} fontWeight={"bold"}>{batch.cuenta.nombre} ({batch.agente_de_custodia.nombre})</Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                      <Typography variant="caption" fontStyle={"italic"}>Precio por acción:</Typography>
                      <Typography variant="caption" fontStyle={"italic"}>{moneda.nombre_corto + " " + getAmountText(Math.abs(batch.precio / batch.cantidad))}</Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <DeleteIcon fontSize='medium' color="primary" onClick={event => handleDeleteClick(event, batch)} />
                </Stack>
              </Stack>
            </Stack>
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  </Stack>
}

AccionPortafolioListItem.propTypes = {
  accion: accionPropType.isRequired,
  moneda: currencyPropType.isRequired,  // The currency in which the stock is nominally held
  key: PropTypes.number.isRequired,
  currentQuote: stockQuotePropType.isRequired,
  onBatchDelete: PropTypes.func.isRequired,
  onBatchEdit: PropTypes.func.isRequired,
  onBatchCreate: PropTypes.func.isRequired,
}

export default AccionPortafolioListItem;